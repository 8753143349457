
import api from '@/api'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const id = ref('')
    const token = ref('')
    const refreshToken = ref('')
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('@p-programisci.pl')

    async function addUser() {
      api.users.create({
        id: +id.value,
        accessToken: token.value,
        refreshToken: refreshToken.value,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        picture:
          'https://lh3.googleusercontent.com/a/AATXAJyEUYiDPk1swAe_dWZ0DzdVVbTlM36zQDTq8no-=s96-c'
      })
    }

    return {
      addUser,
      email,
      id,
      token,
      firstName,
      lastName
    }
  }
})
